import { DefaultOptionType } from "antd/es/select";
import { RpUser } from 'screens/login/stores/types';
import store from "shared/stores/store";
import api, { unwrapAxiosResult } from "shared/utils/api";
import { API_URLs } from "shared/utils/constants";
import { contextActions } from "./actions";
import { BircodeData as BirCodeData, RoleData, RoleList, RolePrivilege, RoleRepositories, roleMapping } from "./types";

const RP4_SUBSCRIBER = (birCode: string) => `${API_URLs.RP4_API}/referential/accounts/${birCode}/provider?repository=BIR`;

export const getRp4Subscriber = (birCode: string) =>
    unwrapAxiosResult(api.get(RP4_SUBSCRIBER(birCode)));

/**
 * @param user 
 * @returns Bircode Data extracted from user.
 */
export const getBirCodeDataFromUser = (user: RpUser | undefined): BirCodeData | undefined => {
    let selectedBircodeData: BirCodeData | undefined = undefined
    let bircodeDataString = localStorage.getItem("bircodeData");
    let myRoleData = getRoleDataFromUser(user)
    if (bircodeDataString) selectedBircodeData = JSON.parse(bircodeDataString)
    if (myRoleData.myRolePrivilege === RolePrivilege.R1 && !bircodeDataString) {
        if (user && user.bircode) {
            selectedBircodeData = {
                bircode: user.bircode,
                businessName: "",
                city: ""
            }
        }
    }
    return selectedBircodeData;
}

/**
 * @param user 
 * @returns Role data extracted from user.
 */
export const getRoleDataFromUser = (user: RpUser | undefined): RoleData => {
    const roleList = getRoleListFromUser(user)
    const rolePrivilege = getRolePrivilegeFromUser(user)

    return {
        myRoleList: roleList,
        myRolePrivilege: rolePrivilege
    }
}

/**
 * @param user
 * @returns Role list extracted from user.
 */
export const getRoleListFromUser = (user: RpUser | undefined): RoleList => {
    let roles: Array<string> = [];
    RoleRepositories.forEach(repository => {
        if (user && user.profile && user.profile[repository] && typeof user.profile[repository] === 'string') {
            roles = [...roles, user?.profile[repository]]
        }
        else if (user && user.profile && user.profile[repository] && typeof user.profile[repository] !== 'string') {
            user.profile[repository].forEach((role: string) => roles = [...roles, role])
        }

    })
    return roles
}

/**
 * @param user
 * @returns Role privilege extracted from user.
 */
export const getRolePrivilegeFromUser = (user: RpUser | undefined): RolePrivilege => {
    let currentRolePrivilege: RolePrivilege = RolePrivilege.NONE;
    const roleList = getRoleListFromUser(user);

    roleMapping.forEach(roleMapping => {
        if (roleList && roleMapping.roles.some((ai: string) => roleList.includes(ai)) && roleMapping.privilege > currentRolePrivilege)
            currentRolePrivilege = roleMapping.privilege
    });

    return currentRolePrivilege;
}

/**
 * @param requiredRolePrivilege Required Role Privilege to access ressource.
 * @returns boolean : True if granted, else is false.
 */
export const isRolePrivilegeGranted = (requiredRolePrivilege: RolePrivilege): boolean => {
    const myRolePrivilege: RolePrivilege = store.getState().rp4.context.roleData.myRolePrivilege;
    return myRolePrivilege >= requiredRolePrivilege
}

export const birCodeDataListToOptionList = (birCodeDataList: BirCodeData[]): DefaultOptionType[] => (
    birCodeDataList.map(x => birCodeDataToOption(x))
)

export const birCodeDataToOption = (birCodeData: BirCodeData): DefaultOptionType => ({
    value: JSON.stringify(birCodeData),
    label: `${birCodeData.businessName} - ${birCodeData.bircode} - ${birCodeData.city}`,
    key: birCodeData.bircode,
})

export const findBirCodeData = (birCode: string, birCodeDataList: BirCodeData[]): BirCodeData | undefined => {
    return birCodeDataList.find(x => x.bircode === birCode);
}

export const setBirCodeData = (birCode: string) => {
    let success = false;
    if (isRolePrivilegeGranted(RolePrivilege.COUNTRY_SUBSCRIPTION)) {
        const birCodeDataList = store.getState().rp4.context.allBIRCodeDataList;
        if (birCodeDataList) {
            const newBirCodeData = findBirCodeData(birCode, birCodeDataList)
            if (newBirCodeData) {
                store.dispatch(contextActions.setMyBirCodeData(newBirCodeData));
                success = true;
            }
        }
    }
    if (!success) store.dispatch(contextActions.setMyBirCodeDataError());
}